
import { appState, exportTransactionsFunction } from "@/main";
import PointsOverview from '@/components/PointsOverview.vue';
import SalespersonList from '@/components/SalespersonList.vue';
import TransactionsList from '@/components/TransactionsList.vue';
import { rewardsStore } from "@/store/rewardsStore";
import { getBuilder } from "@/ts/api";
import { defineComponent, onMounted, Ref, ref, watch } from "@vue/runtime-core";
import { f7 } from "framework7-vue";

export default defineComponent({
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    PointsOverview,
    SalespersonList,
    TransactionsList,
  },
  setup() {
    const canViewLoyaltyProgram = ref(false);
    const isLoadingUserData = ref(true);

    if(appState.builderData) {
      if (appState.builderData?.roles.canViewLoyaltyProgram) {
        canViewLoyaltyProgram.value = true;
        isLoadingUserData.value = true;
        rewardsStore.loadData(appState.builderData.builderID).then((result) => {
          isLoadingUserData.value = false;
              if (result.errors && result.errors.length) {
                result.errors.forEach(err => {
                  console.error(err);
                });
              }
            });
      } else {
        canViewLoyaltyProgram.value = false;
        isLoadingUserData.value = false;
      }
    }
    
    watch(
      () => appState.builderData,
      (builder) => {
        if (builder) {
          if (builder?.roles.canViewLoyaltyProgram) {
            canViewLoyaltyProgram.value = true;
            isLoadingUserData.value = true;
            rewardsStore.loadData(builder.builderID).then((result) => {
              isLoadingUserData.value = false;
              if (result.errors && result.errors.length) {
                result.errors.forEach(err => {
                  console.error(err);
                });
              }
            });
          } else {
            canViewLoyaltyProgram.value = false;
            isLoadingUserData.value = false;
          }
        }
      },
    );

    const fromDate: Ref<Date[]> = ref([]);
    const toDate: Ref<Date[]> = ref([]);
      const exportingTransactions = ref(false);
    const exportTransactions = async () => {
      if(exportingTransactions.value) return;
      exportingTransactions.value = true;
      try {
        const result = await exportTransactionsFunction({ fromDate: fromDate.value[0].getTime(), toDate: toDate.value[0].getTime() });
        const blob = new Blob([result.data], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "transactions.csv");
        link.click();
      } catch (error: any) {
        f7.dialog.alert(error.message);
        console.error("Error exporting transactions:", error);
      }
      finally {
        exportingTransactions.value = false;
      }
    };
    return {
      fromDate,
      toDate,
      exportTransactions,
      canViewLoyaltyProgram,
      isLoadingUserData,
      exportingTransactions,
    };

  },
  computed: {
    isAdmin: () => appState.admin,
  },
});
