import { reactive } from 'vue';

import { Builder, getBuilder } from '@/ts/api';
import { addSalesAgentFunction, removeSalesAgentFunction, updateTransactionFunction, deleteTransactionFunction, getTransactionsFunction, getSalesAgentsFunction, getTotalPointsFunciton as getPointsFunction } from '@/main';

export interface Transaction {
  id?: string;
  builderID?: string;
  price: number;
  points: number;
  description: string;
  createdAt?: Date;
}

function ObjToTransaction(transaction: any): Transaction {
  return {
    id: transaction.id,
    builderID: transaction.builderID,
    price: Number(transaction.price),
    points: Number(transaction.points),
    description: transaction.description,
    createdAt: new Date(+transaction.createdAt._seconds * 1000),
  } as Transaction;
}
function OccuredThisMonth(transaction: Transaction) {
  const currentDate = new Date();
  const transactionDate = transaction.createdAt || new Date();
  return (
    transactionDate.getFullYear() === currentDate.getFullYear() &&
    transactionDate.getMonth() === currentDate.getMonth()
  );
}
export interface SalesAgent {
  id: string;
  name: string;
  email: string;
  points: number;
  canViewLoyaltyProgram: boolean;
}

export const rewardsStore = reactive({
  isLoading: false,
  salespersons: [] as SalesAgent[],
  transactions: [] as Transaction[],
  monthlyPoints: 0,
  totalPoints: 0,
  currentUser: null as Builder | null,
  transactionsPageToken: null as number | null,

  async addSalesAgent(agentID: string, seesPoints: boolean): Promise<{ error?: string }> {
    try {
      await addSalesAgentFunction({
        parentID: this.currentUser?.builderID,
        agentID: agentID,
        seesPoints: seesPoints,
      });

      await this.loadData(this.currentUser!.builderID);
      return {};
    } catch (error: any) {
      console.error("Error adding salesperson:", error.message);
      return { error: error.message };
    }
  },
  async removeSalesAgent(id: string): Promise<{ error?: string }> {
    try {
      await removeSalesAgentFunction({
        parentID: this.currentUser?.builderID,
        agentID: id,
      });

      await this.loadData(this.currentUser!.builderID);
      return {};
    } catch (error: any) {
      console.error("Error removing salesperson:", error.message);
      return { error: error.message };
    }
  },
  async updateTransaction(transaction: Transaction): Promise<{ error?: string }> {
    try {
      const res = await updateTransactionFunction({ ...transaction, builderID: this.currentUser?.builderID });
      if (!res.data) return { error: "No data returned" };

      const updatedTransaction = ObjToTransaction(res.data);

      // if this is a new transaction
      if (!transaction.id) {
        this.transactions = [updatedTransaction, ...this.transactions];
        this.totalPoints += updatedTransaction.points;
        if (OccuredThisMonth(updatedTransaction)) this.monthlyPoints += updatedTransaction.points;
        return {};
      }

      // if this is an existing transaction
      const index = this.transactions.findIndex(t => t.id === updatedTransaction.id);
      if (index !== -1) {
        const oldTransaction = this.transactions[index];
        this.transactions[index] = updatedTransaction;
        const pointsDelta = updatedTransaction.points - oldTransaction.points;
        this.totalPoints = this.totalPoints + pointsDelta;
        if (OccuredThisMonth(updatedTransaction)) this.monthlyPoints = this.monthlyPoints + pointsDelta;
      }
      return {};
    } catch (error: any) {
      console.error("Error saving transaction:", error.message);
      return { error: error.message };
    }
  },
  async deleteTransaction(transactionID: string): Promise<{ error?: string }> {
    try {
      const result = await deleteTransactionFunction({ builderID: this.currentUser?.builderID, transactionID });
      if (result.data?.success) {
        const deletedTransaction = this.transactions.find(t => t.id === transactionID);
        if (deletedTransaction) {
          this.totalPoints -= deletedTransaction.points;
          this.transactions = this.transactions.filter(t => t.id !== transactionID);
          if (OccuredThisMonth(deletedTransaction)) this.monthlyPoints -= deletedTransaction.points;
        }
        return {};
      }
      return { error: "Unable to delete transaction." };
    } catch (error: any) {
      console.error("Error deleting transaction:", error.message);
      return { error: error.message };
    }
  },
  async loadData(builderID: string): Promise<{ errors?: string[] }> {
    this.isLoading = true; // start loading
    // Clear existing data
    this.salespersons = [];
    this.transactions = [];
    this.monthlyPoints = 0;
    this.totalPoints = 0;
    this.currentUser = null;
    const errors: string[] = [];

    const [builderResult, transactionsResult, salesAgentsResult, getPointsResult] = await Promise.allSettled([
      getBuilder(builderID),
      getTransactionsFunction({ builderID, pageSize: "50" }),
      getSalesAgentsFunction({ parentID: builderID }),
      getPointsFunction({ builderID }),
    ]);

    if (builderResult.status === 'fulfilled') {
      this.currentUser = builderResult.value;
    } else {
      errors.push(`Error loading builder: ${builderResult.reason?.message || builderResult.reason}`);
    }

    if (transactionsResult.status === 'fulfilled' && transactionsResult.value.data) {
      const txData = transactionsResult.value.data;
      console.log("transaction ids:", txData.transactions);
      this.transactions = txData.transactions.map((tx: Transaction) => ObjToTransaction(tx));
      this.transactionsPageToken = txData.nextPageToken;
    } else {
      errors.push(`Error loading transactions: ${transactionsResult.status === 'rejected' ? (transactionsResult.reason?.message || transactionsResult.reason) : 'No data returned'}`);
    }

    if (salesAgentsResult.status === 'fulfilled' && salesAgentsResult.value.data) {
      this.salespersons = salesAgentsResult.value.data as SalesAgent[];
    } else {
      errors.push(`Error loading sales agents: ${salesAgentsResult.status === 'rejected' ? (salesAgentsResult.reason?.message || salesAgentsResult.reason) : 'No data returned'}`);
    }

    if (getPointsResult.status === 'fulfilled' && getPointsResult.value.data) {
      this.totalPoints = +getPointsResult.value.data.totalPoints;
      this.monthlyPoints = +getPointsResult.value.data.monthlyPoints;
    } else {
      errors.push(`Error loading total points: ${getPointsResult.status === 'rejected' ? (getPointsResult.reason?.message || getPointsResult.reason) : 'No data returned'}`);
    }

    this.isLoading = false; // finish loading
    return errors.length ? { errors } : {};
  },
  async loadMoreTransactions(): Promise<{ error?: string, allLoaded?: boolean }> {
    try {
      const response = await getTransactionsFunction({
        builderID: this.currentUser?.builderID,
        startAfter: this.transactionsPageToken,
        pageSize: "20",
      });

      const { transactions, nextPageToken } = response.data;
      const newTransactions = transactions.map((transaction: any) => ObjToTransaction(transaction));
      this.transactions = [...this.transactions, ...newTransactions];
      this.transactionsPageToken = nextPageToken;

      return { allLoaded: !nextPageToken };
    } catch (error) {
      console.error("Error loading more transactions", error);
      return { error: "Failed to load more transactions." };
    }
  }
});
