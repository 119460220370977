import { Product } from "@/../../functions/src/types";
import { appState, NODE_SERVER_URL } from "@/main";
export const currency = new Intl.NumberFormat(`en-US`, {
  currency: `USD`,
  style: "currency",
});

export interface Address {
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

/** Interface for what a item looks like on firestore */
export interface FirebaseItem {
  sku: string;
  notes: string;
  count: number;
  loyaltyPoints: number;
}

export enum shippingPackage {
  plumbingRoughIn = "plumbingRoughIn",
  trimAndStartUp = "trimAndStartUp",
}

export class Item {
  _sku: string;
  _description: string;
  _number: string;
  _price: number;
  _count: number;
  _packageID?: shippingPackage;
  _loyaltyPoints?: number;
  notes: string;

  protected constructor(
    sku: string,
    number: string,
    name: string,
    price?: number,
    count?: number,
    packageID?: shippingPackage,
    loyaltyPoints?: number,
    notes?: string
  ) {
    this._sku = sku;
    this._description = name;
    this._number = number;
    this._price = price ?? 0;
    this._count = count ?? 0;
    this._packageID = packageID;
    this._loyaltyPoints = loyaltyPoints;
    this.notes = notes ?? "";
  }

  static createItem(sku: string,
    number: string,
    name: string,
    price?: number,
    count?: number,
    packageID?: shippingPackage,
    loyaltyPoints?: number,
    notes?: string): Item {
    if (sku[0] === "J") {
      return new NozzleItem(sku, number, name, price, count, packageID, loyaltyPoints, notes);
    } else if (sku.startsWith("DJ")) {
      return new DownJetItem(sku, number, name, price, count, packageID, loyaltyPoints, notes);

    } else if (sku.startsWith('COL-DR')) {
      return new DrainCoverItem(sku, number, name, price, count, packageID, loyaltyPoints, notes);

    } else if (sku.startsWith("COL-SF")) {
      return new DrainSumpItem(sku, number, name, price, count, packageID, loyaltyPoints, notes);
    }
    return new Item(sku, number, name, price, count, packageID, loyaltyPoints, notes);
  }

  get imageUrl(): string {
    if (this.sku[0] === "J")
      return "https://firebasestorage.googleapis.com/v0/b/fancy-pools.appspot.com/o/product_images%2FSpringNozzle.png?alt=media&token=3b4ed232-14ee-403a-8d63-c03124312ebf";

    return `https://www.googleapis.com/download/storage/v1/b/fancy-pools.appspot.com/o/product_images%2f${this.sku}.png?alt=media`;
  }

  get number(): string {
    return this._number;
  }

  get name(): string {
    return this._description;
  }

  public get hasError(): boolean {
    return (
      this.name.length == 0 || this.number.length == 0 || this.sku.length == 0
    );
  }

  /** Get an item from our fishbowl records via its ID */
  static async getItem(sku: string) {
    //TODO get items from firebase
    return new Item(sku, "todo", "TODO get fishbowl data", 404);
  }

  get sku(): string {
    return this._sku;
  }

  set count(value: number) {
    if (value < 0) throw "Invalid range";
    this._count = value;
  }

  get count(): number {
    return this._count;
  }

  updateCount(value: number): void {
    this._count = Math.max(0, this.count + value);
  }
  get price(): number {
    return this._price;
  }
  get formattedPrice(): string {
    return currency.format(this._price);
  }

  get packageID(): shippingPackage {
    if (this._packageID) return this._packageID;
    return this.sku.includes("CKT")
      ? shippingPackage.plumbingRoughIn
      : shippingPackage.trimAndStartUp;
  }

  get loyaltyPoints(): number {
    return this._loyaltyPoints ?? 0;
  }

  /** This just formats the [packageID] to title case. */
  get packageName(): string {
    const res = this.packageID.replace(/([A-Z])/g, " $1");
    return res.charAt(0).toUpperCase() + res.slice(1);
  }

  /** convert to object for firestore*/
  toObject(): FirebaseItem {
    const _object: FirebaseItem = {
      sku: this.sku,
      notes: this.notes,
      count: this.count,
      loyaltyPoints: this.loyaltyPoints,
    };
    return _object;
  }
  /** convert to object for firestore*/
  // fromObject(object: any) {
  //   this._id = object.sku;
  //   this.count = object.count;
  // }
  set color(c: string) {
    if (!this.hasColor || !this.sku || this.sku.length < 3) throw Error("Item does not have a color code in its sku");
    if (!this.colorMap.has(c)) throw "Invalid color";
    const split = this.sku.split("-");
    const oldColor = split[this.skuColorIndex];
    if (oldColor === c) throw "Color is already set to " + c;
    split[this.skuColorIndex] = this.colorMap.get(c)!;
    this._sku = split.join("-");
  }

  updateItem(newValue: Item) {
    this._sku = newValue.sku;
    this._description = newValue.name;
    this._number = newValue.number;
    this._price = newValue.price;
    this._packageID = newValue.packageID;
    this._loyaltyPoints = newValue.loyaltyPoints;
    this.notes = newValue.notes;
  }

  get localColor(): string {
    if (!this.hasColor || !this.sku || this.sku.length < 3) throw Error("Item does not have a color code in its sku");
    return this.sku.split("-")[this.skuColorIndex];
  }

  matchesColor(color: string): boolean {
    // 3 characters is the smallest possible sku with a color code: J-[color]
    if (!this.hasColor || !this.sku || this.sku.length < 3) return false;
    const localColor = this.sku.split("-")[this.skuColorIndex];

    // find every key that has the same value as the color we are looking for
    for (const [key, value] of this.colorMap) {
      if (value === localColor) {
        // if the key is the same as the color we are looking for, return true
        if (key === color) return true;
      }
    }
    return false;
  }

  /** This is a map of the available colors to what color should be used for this sku
   * 
   * Color codes:
   * 
   * W - White
   * 
   * LG - Light Gray
   * 
   * DG - Dark Gray
   * 
   * BL - Black
   * 
   * BE - Beige
   * 
   * LB - Light Blue
  */

  get colorMap(): Map<string, string> {
    return new Map([
      ["LG", "LG"],
      ["BE", "BE"],
      ["W", "W"],
      ["DG", "DG"],
      ["BL", "BL"],
      ["LB", "LB"],
    ]);
  }

  // Items by default have no color index
  get skuColorIndex(): number {
    return -1;
  }

  get hasColor(): boolean {
    return this.skuColorIndex != -1;
  }
}

export class NozzleItem extends Item {

  get skuColorIndex(): number {
    return 1;
  }
}

export class DrainSumpItem extends Item {
  get colorMap(): Map<string, string> {
    return new Map([
      ["LG", "LG"],
      ["BE", "LG"],
      ["W", "W"],
      ["DG", "G"],
      ["BL", "BL"],
      ["LB", "LG"],
    ]);
  }

  get skuColorIndex(): number {
    return 3;
  }
}

export class DrainCoverItem extends Item {
  get colorMap(): Map<string, string> {
    return new Map([
      ["LG", "LG"],
      ["BE", "LG"],
      ["W", "W"],
      ["DG", "G"],
      ["BL", "BL"],
      ["LB", "LG"],
    ]);
  }

  get skuColorIndex(): number {
    return 3;
  }
}

export class DownJetItem extends Item {
  get colorMap(): Map<string, string> {
    return new Map([
      ["LG", "LG"],
      ["BE", "LG"],
      ["W", "WH"],
      ["DG", "LG"],
      ["BL", "LG"],
      ["LB", "LG"],
    ]);
  }

  get skuColorIndex(): number {
    return 3;
  }
}

/**
 * Retrieves items from the server based on their SKUs.
 * @param skus - An array of SKUs to retrieve.
 * @param customerID - An optional Fishbowl Customer ID to filter the results by for discounts.
 * @returns An array of items matching the provided SKUs.
 */
export async function getItems(skus: string[], customerID?: string) {
  if ((skus ?? []).length == 0) return [];
  const _items: { [key: string]: Item } = {};
  skus.forEach((s) => (_items[s] = Item.createItem(s, "", "")));
  try {
    let url = `${NODE_SERVER_URL}/products?skus=${skus.join(",")}`;
    const id = customerID ?? appState.builderData?.customerID;
    if (id != undefined)
      url = url.concat(`&customerId=${id}`);
    const res = await fetch(url);
    const data = (await res.json()) as Product[];
    data.forEach((p: Product) => {
      if (skus.includes(p.sku)) {

        // check for custom fields
        let packageID;
        let loyaltyPoints = 0;
        for (const [key, value] of Object.entries(p.customfields)) {
          if ((value as any)["name"] === "packageId")
            packageID = (value as any)["value"];
          else if ((value as any)["name"] === "loyaltyPoints")
            loyaltyPoints = Number((value as any)["value"] ?? 0);
        }

        _items[p.sku] = Item.createItem(
          p.sku,
          p.num,
          p.description,
          p.price,
          0,
          packageID,
          loyaltyPoints,
        );
      }
    });
  } catch (e) {
    console.error(e);
  }
  return Object.values(_items);
}

// setup singleton
export const getWarehouse = async () => {
  if (!noop) {
    const _noop = async () => {
      let url = `${NODE_SERVER_URL}/warehouse`;
      if (!appState.admin)
        url = url.concat("?customerId=" + appState.builderData!.customerID);
      const res = await fetch(url);

      const data = (await res.json()) as Product[];
      const warehouse = [] as Item[];
      data.forEach((p: Product) => {

        // some products don't have the packageId custom field
        let _packageID;
        for (const [key, value] of Object.entries(p.customfields)) {
          if ((value as any)["name"] === "packageId")
            _packageID = (value as any)["value"];
        }

        if (p.sku[0] != "J")
          warehouse.push(
            Item.createItem(p.sku, p.num, p.description, p.price, 0, _packageID)
          );
      });
      return warehouse;
    };
    noop = _noop();
  }

  return await noop;
};

let noop: Promise<Item[]>;
