// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import '../api';

export interface Program {
  jobID?: string;
  builderID: string;
  name: string;
  imageUrl: string;
  portMap: number[];// Maybe turn this into a array of strings
  zones: { duration: number; cycles: number }[];
  sets: number,
  singlePump: boolean,
  /** If this device has a dummy port swapping thing...*/
  dummyDevice: boolean;
  lastModifiedDate: firebase.firestore.Timestamp;
}

export function getTotalProgramDuration(program: Program) {

  const steps: string[] = genGcode(program);
  if (steps.length === 0) return "00:00";
  let seconds = 0;
  let prevPort: number = parseInt(steps[0].substring(0, 1));

  for (let i = 0; i < steps.length; i++) {
    const stepValue: string = steps[i];
    const port: number = parseInt(stepValue.substring(0, 1));
    const duration: number = parseInt(stepValue.substring(1));

    seconds += timeToPort(prevPort, port) + duration;

    if (port === 0 && prevPort !== 0) {
      // Uncomment the line below to print the time taken for the previous step
      // console.log(timeToPort(prevPort, port) + duration);
    }

    prevPort = port;
  }

  // Uncomment the line below to log the total time and _seconds
  // console.log(`Totaltime: ${totalSeconds.toString()} _seconds: ${seconds.toString()}`);

  // Seconds plus 20 min buffer as specified in specs
  return secondsToTimeFormat(seconds + 1200);
}

function secondsToTimeFormat(seconds: number): string {
  const hours: number = Math.floor(seconds / 3600);
  const minutes: number = Math.floor((seconds % 3600) / 60);

  const hoursPart: string = hours > 0 ? `${hours}H` : '';
  const minutesPart: string = minutes > 0 ? `${minutes}M` : '';

  return hoursPart + ' ' + minutesPart;
}

function genGcode(program: Program) {

  const steps: string[] = [];

  const _secondary = program.portMap.length > 8;
  const _whirlpool = program.singlePump;
  // The port that the secondary zone is on
  const _secondaryZonePort = (_whirlpool) ? 6 : 7;
  let fakeZones = 0;
  if (_secondary) {
    fakeZones = program.zones.length - 7;
  }
  // Go through each set
  for (let i = 0; i < program.sets; i++) {
    // Get the gcode from each of the zones(can really only be 8 zones (only 8 ports) the rest are fake illusions to make people feel better)
    for (let x = 0; x < program.zones.length && x < 8; x++) {
      let e = program.zones[x];
      let _zoneGcode: string[] = [];

      // WHIRLPOOL ZONE
      if (_whirlpool && x == 7) {
        e = program.zones[program.zones.length - 1];
        _zoneGcode = generateWhirlPoolZoneGcode(i, program.sets, e.cycles, x, e.duration);
      }
      // SECONDARY ZONE
      else if (_secondary && x == _secondaryZonePort) {
        _zoneGcode = generateSecondaryZoneGcode(e.cycles, x, e.duration, fakeZones);
      }
      // NORMAL ZONE
      else {
        // Get the ports
        const ports: number[] = [];
        program.portMap.forEach((v, _index) => {
          // If the port is assigned to this zone 
          if (v == x) {
            ports.push(_index);
          }
        });

        // Generate zone gcode
        _zoneGcode = generateZoneGcode(e.cycles, ports, e.duration)
      }

      _zoneGcode.forEach(element => {
        steps.push(element);
      });
    }
  }

  return steps;

}

function generateWhirlPoolZoneGcode(set: number, sets: number, cycles: number, port: number, duration: number): string[] {
  const defaultDuration = 1800;
  const defaultCycles = 1;

  // Only set the duration and cycles the last set
  const _dur: number = (set === sets - 1) ? duration : defaultDuration;
  const _cyc: number = (set === sets - 1) ? cycles : defaultCycles;

  const _steps: string[] = [];
  const _cyclesPerPort: number = Math.floor(_cyc / port);
  let _remainder: number = _cyc % port;

  let _portCycles: number = _cyclesPerPort;

  // distribute the cycle remainder
  if (_remainder > 0) {
    _remainder--;
    _portCycles++;
  }

  for (let x = 0; x < _portCycles; x++) {
    _steps.push(port.toString() + _dur.toString());
  }

  return _steps;

}

function generateSecondaryZoneGcode(cycles: number, port: number, duration: number, fakeZones: number): string[] {
  const _steps: string[] = [];
  let _cyclesPerPort: number = cycles * 8;

  // If 4 or fewer fake zones then only cycle 4 times per port
  if (fakeZones <= 4) {
    _cyclesPerPort = cycles * 4;
  }

  // console.log(`CyclesPer port ${_cyclesPerPort} Zones: ${zones}`);

  for (let x = 0; x < _cyclesPerPort; x++) {
    _steps.push(port.toString() + duration.toString());
  }

  return _steps;

}

function generateZoneGcode(cycles: number, ports: number[], duration: number): string[] {

  // Ensure we always have enough cycles to traverse the ports in the zone
  if (cycles < ports.length) {
    cycles = ports.length;
  }

  if (ports.length > 0) {
    const steps: string[] = [];
    const cyclesPerPort: number = Math.floor(cycles / ports.length);
    let remainder: number = cycles % ports.length;

    for (let i = 0; i < ports.length; i++) {
      let portCycles: number = cyclesPerPort;

      // distribute the cycle remainder
      if (remainder > 0) {
        remainder--;
        portCycles++;
      }

      for (let x = 0; x < portCycles; x++) {
        steps.push(ports[i].toString() + duration.toString());
      }
    }

    // console.log(`generated zone GCODE: ${ports.toString()}, ${steps.toString()}`);
    return steps;
  } else {
    return [];
  }
}

function timeToPort(currentPort: number, targetPort: number): number {
  let seconds = 0;
  let ports = 0;

  if (currentPort < targetPort) {
    ports = targetPort - currentPort;
  } else if (currentPort > targetPort) {
    ports = 8 - currentPort + targetPort;
    seconds -= 150; // Error corrections
  }

  if (ports > 0) {
    // Unpause port advance
    seconds += 3;
  }

  // Close port 7 sec stepper rotation + 8-second wait time
  seconds += 7 + 8;

  // Start at 1 because we already have 1 close and open in the function
  for (let i = 0; i < ports; i++) {
    // Open and close stepper valve
    seconds += 7 + 8 + 7 + 4;
  }

  // Open port 7 sec stepper rotation + 4-second wait time
  seconds += 7 + 4;

  if (ports > 0) {
    // Pause port advance
    seconds += 3;
  }

  // Buffer
  // seconds += 1;

  return seconds;
}




/** The global zone mapping array. Stores the zone map for a given number of zones */
export const zoneValues: number[][] = [
  // Zones 1-3 empty
  [],
  [],
  [],
  // 4 Zone mapping
  [
    0,
    0,
    1,
    1,
    2,
    2,
    3,
    // Whirlpool
    3,
  ],
  // 5 Zone mapping
  [
    0,
    0,
    1,
    1,
    2,
    2,
    3,
    // Whirlpool
    4,
  ],
  // 6 Zone mapping
  [
    0,
    0,
    1,
    1,
    2,
    3,
    4,
    // Whirlpool
    5,
  ],
  // 7 Zone mapping
  [
    0,
    0,
    1,
    2,
    3,
    4,
    5,
    // Whirlpool
    6,
  ],
  // 8 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    6,
    // Whirlpool
    7,
  ],
  // SLAVE DEVICE SECTION(15 ports)
  // 9 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    6,
    6,
    7,
    7,
    7,
    7,
    // Whirlpool
    8,
  ],
  // 10 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    6,
    6,
    7,
    7,
    8,
    8,
    // Whirlpool
    9,
  ],
  // 11 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    7,
    8,
    8,
    9,
    9,
    // Whirlpool
    10,
  ],
  // 12 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    7,
    8,
    8,
    9,
    10,
    // Whirlpool
    11,
  ],
  // 13 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    7,
    8,
    9,
    10,
    11,
    // Whirlpool
    12,
  ],
  // 14 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    // Whirlpool
    13,
  ],
  // 15 Zone mapping
  [
    0,
    1,
    2,
    3,
    4,
    5,
    // SLAVE PORTS
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    // Whirlpool
    14,
  ],
];

export const defaultProgram: Program = {
  jobID: "",
  builderID: "",
  name: "",
  imageUrl: "",
  portMap: [0, 0, 0, 0, 0, 0, 0, 0],
  zones: [
    { cycles: 18, duration: 30 },
    { cycles: 18, duration: 30 },
    { cycles: 18, duration: 30 },
    { cycles: 18, duration: 30 },
  ],
  sets: 0,
  dummyDevice: false,
  singlePump: false,
  lastModifiedDate: firebase.firestore.Timestamp.now(),
};
