
import { defineComponent, ref, onMounted } from 'vue';
import { f7, f7ready } from 'framework7-vue';
import { algoliaBuilderSearch } from '@/ts/api';
import { rewardsStore } from '@/store/rewardsStore';
import { appState } from '@/main';

export default defineComponent({
  name: 'SalespersonList',
  setup() {
    const showAddRow = ref(false);
    const newSalespersonId = ref('');
    const newSalespersonName = ref('');
    const newSalespersonSeesPoints = ref(true);

    const saveSalesperson = async () => {
      if (newSalespersonId.value) {
        const preloader = f7.dialog.preloader(`Adding ${newSalespersonName.value}...`);
        const res = await rewardsStore.addSalesAgent(newSalespersonId.value, newSalespersonSeesPoints.value);
        preloader.close();
        if (res.error) {
          f7.dialog.alert(res.error);
        }
        cancelAddSalesperson();
      }
    };

    const cancelAddSalesperson = () => {
      showAddRow.value = false;
      newSalespersonId.value = "";
      newSalespersonName.value = "";
      newSalespersonSeesPoints.value = true;
    };

    const confirmRemoveSalesperson = async (id: string) => {
      const salesperson = rewardsStore.salespersons.find(s => s.id === id);
      if (salesperson) {
        f7.dialog.confirm(
          `Are you sure you want to remove ${salesperson.name} from the list?`,
          'Remove Salesperson',
          async () => {
            const preloader = f7.dialog.preloader(`Removing ${salesperson.name}...`);
            const res = await rewardsStore.removeSalesAgent(id);
            preloader.close();
            if (res.error) {
              f7.dialog.alert(res.error);
            }
          }
        );
      }
    };

    onMounted(() => {
      f7ready((app) => {
        app.autocomplete.create({
          inputEl: '#new-salesperson-id',
          openIn: 'dropdown',
          preloader: true,
          source: function (query, render) {
            algoliaBuilderSearch({ query }).then((results) => {
              render(results.map((item) => `${item.id} (${item.title})`));
            });
          },
          on: {
            close: function (autocomplete) {
              const value = autocomplete.value as string[];
              if (value.length > 0) {
                const parts = value[0].split(' ');
                newSalespersonId.value = parts[0];
                if (parts.length > 1) {
                  const name = parts.slice(1).join(' ').replace(/[()]/g, '');
                  newSalespersonName.value = name;
                }
              }
            },
          },
        });
      });
    });

    return {
      rewardsStore,
      showAddRow,
      newSalespersonId,
      newSalespersonName,
      newSalespersonSeesPoints,
      saveSalesperson,
      cancelAddSalesperson,
      confirmRemoveSalesperson,
    };
  },
  computed: {
    isAdmin: () => appState.admin,
  },
});
