
import { defineComponent } from 'vue';
import { rewardsStore } from '@/store/rewardsStore';
import { appState } from '@/main';
export default defineComponent({
  name: 'PointsOverview',
  computed: {
    rewardsStore: () => rewardsStore,
    showTripPreview: () => !appState.admin && (rewardsStore.currentUser?.roles?.canViewLoyaltyProgram ?? false),
  },
  methods: {
    formatNumber: (value: number) => value.toLocaleString()
  }
});
