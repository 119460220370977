
import { defineComponent } from 'vue';
import { f7 } from 'framework7-vue';
import PointsOverview from '@/components/PointsOverview.vue';
import SalespersonList from '@/components/SalespersonList.vue';
import TransactionsList from '@/components/TransactionsList.vue';
import { rewardsStore } from '@/store/rewardsStore';

export default defineComponent({
  name: 'Rewards',
  components: {
    PointsOverview,
    SalespersonList,
    TransactionsList,
  },
  props: {
    builderID: String,
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      newSalespersonId: "",
      newSalespersonName: "",
      newSalespersonSeesPoints: true,
    };
  },
  created() {
    if (this.builderID) {
      rewardsStore.loadData(this.builderID).then((result) => {
        if (result.errors && result.errors.length) {
          result.errors.forEach(err => {
            f7.toast.create({ text: err, closeButton: true, position: 'bottom' }).open();
          });
        }
      });
    } else {
      f7.dialog.alert("No builderID provided");
    }
  },
  computed: {
    rewardsStore: () => rewardsStore,
  },
});
