
import BillOfMaterials from "@/components/BillOfMaterials.vue";
import { appState, storageRef } from "@/main";
import {
  algoliaBuilderSearch,
  deleteJob,
  EngineeringFormData,
  finalizeJob,
  getBuilder,
  getJob,
  Job,
  JobState,
  submitJob,
  zoneValues,
} from "@/ts/api";
import { Item, getItems } from "@/ts/api/store";
import firebase from "firebase";
import { f7, f7ready } from "framework7-vue";
import Framework7, { Dialog, Router } from "framework7/types";
import * as csvParser from "papaparse";
import Printd from "printd";
import { ErrorCorrectLevel, QRCode } from "qrcode-generator-ts/js";
import { defineComponent, onMounted, PropType, reactive, Ref, ref } from "vue";
import EngineeringForm from "./EngineeringForm.vue";
import FileUpload from "./FileUpload.vue";
import ImportProgram, { ImportedProgram } from "./ImportProgram.vue";

const printStyle = `
      ul {
        list-style-type: none
      }
      .item-inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 20px;
          border-bottom: 1px solid gray;
        }
        .block-title {
          font-weight: 700;

        }
        a {
          display: none;
        }
        .dont-print{
          display:none;
        }
        `;

export default defineComponent({
  components: {
    EngineeringForm,
    BillOfMaterials,
    FileUpload,
    ImportProgram,
  },
  props: {
    f7route: Object as PropType<Router.Route>,
    f7router: Object as PropType<Router.Router>,
  },
  setup(props) {
    const pageState = reactive({ job: new Job() });
    const jobID = props.f7route?.params.jobID ?? "new";
    const printD = new Printd();
    if (appState.admin && pageState.job.state == JobState.Draft) {
      pageState.job.builderID = props.f7route?.query.builderID ?? "";
    }
    appState.activeJob = jobID;

    // Ref declaration
    const newJob: Ref<boolean> = ref(jobID === "new"); // If this is the new job page
    const fetching: Ref<boolean> = ref(true);
    const currentImage: Ref<string | null> = ref(null);
    const qrImage: Ref<string | null> = ref(null);
    const engineeringForm: Ref<typeof EngineeringForm | null> = ref(null);

    if (newJob.value) {
      // TODO Create a new job/fill in the default info
      fetching.value = false;
    } else {
      // Load
      getJob(jobID).then((_job) => {
        if (_job == null) {
          f7.dialog.alert("Failed to get job with id: " + jobID, () =>
            props.f7router?.navigate("/jobs", { ignoreCache: true })
          );
          return;
        }
        pageState.job = _job;
        currentImage.value = pageState.job.program.imageUrl;
        fetching.value = false;

        // Generate QR code
        let qr = new QRCode();
        qr.setTypeNumber(5);
        qr.setErrorCorrectLevel(ErrorCorrectLevel.M);
        qr.addData(jobID ?? "NULL"); // Alphabet and Number
        qr.make();
        qrImage.value = qr.toDataURL();
      });
    }

    onMounted(() => {
      f7ready((app) => {
        if (appState.admin && (pageState.job?.state ?? "") == JobState.Draft) {
          let autocomplete = app.autocomplete.create({
            inputEl: "#builder-id",
            openIn: "dropdown",
            preloader: true,
            source: function (query, render) {
              autocomplete.preloaderShow();
              algoliaBuilderSearch({
                query: query,
              }).then((e) => {
                autocomplete.preloaderHide();
                // Render items by passing array with result items
                render(e.map((i) => `${i.id} (${i.title})`));
              });
            },
            on: {
              close: function (a) {
                let v = a.value as string[];
                // If the current field has a autocomplete suggestion
                if (v.length > 0) pageState.job.builderID = v[0].split(" ")[0];
              },
            },
          });
        }
      });
    });

    return {
      pageState: pageState,
      currentImage: currentImage,
      qrImage: qrImage,
      newJob: newJob,
      fetching: fetching,
      engineeringForm: engineeringForm,
      saveButtonText: ref("Save"),
      printD: printD,
      filesLength: 0,
    };
  },

  computed: {
    appState() {
      return appState;
    },
    canEdit() {
      let _canEdit = false;
      if (appState.admin) {
        _canEdit = true;
      } else if (this.pageState.job.state == JobState.Draft) {
        _canEdit = true;
      }
      return _canEdit; // TODO
    },
    showBOM(): boolean {
      return this.pageState.job.state != JobState.Draft;
    },
    zoneMap(): Array<number> {
      return (
        zoneValues[this.pageState.job.program.zones.length - 1] ??
        zoneValues[zoneValues.length - 1]
      );
    },
    getJets(): Item[] {
      return this.pageState.job.bom.filter((i) => i.sku[0] === "J");
    },
    nozzleColor(): string {
      const coloredItems = this.pageState.job.bom.filter((item) => item.hasColor);
        if (coloredItems.length == 0) return "";
        const refColor = this.getJets[0].localColor;
        for (let i = 1; i < coloredItems.length; i++) {
          if (!coloredItems[i].matchesColor(refColor)) return "";
        }
      switch (refColor) {
        case "BL":
          return "black";
        case "W":
          return "white";
        case "DG":
          return "dark gray";
        case "LG":
          return "light gray";
        case "BE":
          return "beige";
        case "LB":
          return "light blue";
        default:
          return "UNKNOWN";

      }
    },
  },
  methods: {
    copyID() {
      let _id = this.pageState.job.ID;
      if (_id != undefined) navigator.clipboard.writeText(_id);
    },
    /** Adds a new zone */
    addZone() {
      if (this.pageState.job.program.zones.length <= 8) {
        this.pageState.job.program.zones.push({ duration: 30, cycles: 18 });
      } else {
        this.pageState.job.program.zones.push({
          duration: 30,
          cycles: this.pageState.job.program.zones[7].cycles,
        });
      }
    },
    /** Deletes a zone at the given index(starts at 0)*/
    deleteZone(index: number) {
      this.pageState.job.program.zones.splice(index, 1);
    },
    /** Sets the programs zone length to the specified number by trimming it or appending to it */
    setZones(amount: number) {
      if (isNaN(amount)) {
        return;
      }
      // Clamp the amount
      if (amount < 4) amount = 4;
      if (amount > 15) amount = 15;

      const _newZones: { duration: number; cycles: number }[] = [];
      for (let i = 0; i < amount; i++) {
        _newZones.push(
          this.pageState.job.program.zones[i] ?? { duration: 30, cycles: 18 }
        );
      }
      this.pageState.job.program.zones = _newZones;
    },
    /** Called when the image file changes */
    async imageFileChange(event: any) {
      if (event.target.files && event.target.files.length > 0) {
        let _imageFile: File = event.target.files[0];
        const loader = f7.dialog.preloader("Uploading image...");
        try {
          let fileRef = storageRef.child(
            `job_files/${this.pageState.job.ID}/program/image`
          );
          const snap = await fileRef.put(_imageFile);
          this.currentImage = await snap.ref.getDownloadURL();
          this.pageState.job.program.imageUrl = this.currentImage!;
          await this.pageState.job.save();
          loader.close();
        } catch (e) {
          loader.close();
          f7.dialog.alert("Failed to upload image");
          console.error(e);
        }
        loader.destroy();
      }
    },
    /** Used for detecting changes to the dummy mode toggle */
    // dummyChange(obj: any) {
    //   this.program.dummyDevice = obj.target.checked;
    // },
    /** Saves the job */
    async save() {
      this.saveButtonText = "Saving...";
      var form = this.engineeringForm?.getData() as EngineeringFormData;
      this.pageState.job.engineeringForm = form;
      if (this.pageState.job.BOMvalid()) {
        try {
          await this.pageState.job.save();
        } catch (e) {
          console.error("Failed to save job.", e);
          f7.toast.show({
            text:
              (e as Error).message ??
              "There was an error saving, please contact support.",
            closeTimeout: 6000,
            closeButton: true,
          });
          this.saveButtonText = "Save";
          return;
        }
        if (this.newJob) {
          this.f7router?.navigate("/jobs/" + this.pageState.job.ID, {
            ignoreCache: true,
          });
          f7.views.main.router.refreshPage();
          // refresh panel
          // this.$emit("refresh");
          return;
        }
        this.saveButtonText = "Saved!";
      } else {
        this.saveButtonText = "ERROR!";
        f7.toast.show({
          text: "There is an error with the BOM.",
          closeTimeout: 6000,
          closeButton: true,
        });
      }
      setTimeout(() => {
        this.saveButtonText = "Save";

        // close the FAB if we are on mobile
        f7.fab.close();
      }, 1500);
    },
    async submit() {
      // check both engineering form and general inputs for validity
      var valid = this.engineeringForm?.validate() as boolean;
      var form = this.engineeringForm?.getData() as EngineeringFormData;
      if (valid && form) this.pageState.job.engineeringForm = form;
      else return;

      const loading = f7.dialog.preloader("Submitting job...");
      await submitJob(this.pageState.job);
      loading.close();
      this.f7router?.navigate("/jobs", { ignoreCache: true });
      // refresh panel
      this.$emit("refresh");
      f7.dialog.alert(
        "Your job was successfully submitted! Give our design team some time to work their magic and we'll email you soon with a finished program and bill of materials for you to review.",
        "Job submitted"
      );
    },
    filesLengthChange(e: any) {
      this.filesLength = e;
    },
    async finalize() {
      if (!this.pageState.job.BOMvalid() || this.pageState.job.bom.length < 1) {
        f7.dialog.alert("Unable to Finalize. There is an error with the BOM.");
        return;
      } else if (!this.pageState.job.programValid()) {
        f7.dialog.alert("Unable to Finalize. There is an error with the program.");
        return;
      } else if (this.pageState.job.program.imageUrl == null || this.pageState.job.program.imageUrl == '') {
        f7.dialog.alert("Unable to Finalize. There is an error with the job image");
        return;
      } else if (this.filesLength < 1) {
        f7.dialog.alert("Unable to Finalize. There are no uploaded files");
        return;
      }


      const loading = f7.dialog.preloader("Finalizing job...");
      await finalizeJob(this.pageState.job);
      loading.close();
      this.f7router?.navigate("/jobs", { ignoreCache: true });

      // refresh panel
      this.$emit("refresh");
    },
    async purchase() {
      if (!this.pageState.job.BOMvalid()) {
        f7.dialog.alert("Unable to Checkout. There is an error with the BOM.");
        return;
      }
      this.pageState.job.cleanBOM();
      let canContinue = true;
      if (this.getJets.length > 0)
        canContinue = await new Promise<boolean>((resolve, reject) => {
          f7.dialog.create(
            {
              text: `You have chosen <b>${this.nozzleColor}</b> as the color for the nozzles, downjets, and drain. If this is incorrect, please go back and change the color in the bill of materials.<br><br> If unsure, select <b>light gray</b> as the color.`,
              title: "Confirm Head Color",
              buttons: [
                {
                  text: "Proceed",
                  onClick: () => {
                    resolve(true);
                  },
                  color: "green",
                },
                {
                  text: "Go Back",
                  onClick: () => {
                    resolve(false);
                  },
                  color: "gray",
                },

              ],
              verticalButtons: true,
              closeByBackdropClick: false,
              destroyOnClose: true,
              animate: true,

            }
          ).open();
        });
      if (!canContinue) return;
      let _builder;
      if (appState.admin) {
        let _loader = f7.dialog.preloader("Loading builder data...");
        _builder = await getBuilder(this.pageState.job.builderID);
        _loader.close();
      } else {
        _builder = appState.builderData;
      }
      // just change status to purchased if the builder is a distribution builder
      if (_builder?.purchaseType == "distribution") {
        this.pageState.job.state = JobState.Purchased;
        await this.pageState.job.save();
        this.$emit("refresh");
        return;
      }

      this.f7router?.navigate("/checkout", {
        ignoreCache: true,
        props: {
          items: this.pageState.job.bom,
          usePackages: appState.admin || (appState.builderData?.roles?.canSplitOrders ?? false),
          builder: _builder,
          PONum: this.pageState.job.name,
          callback: async () => {
            this.pageState.job.state = JobState.Purchased;
            await this.pageState.job.save().catch((e) =>
              f7.toast.show({
                text:
                  (e as Error).message ??
                  "There was an error saving, please contact support.",
                closeTimeout: 6000,
                closeButton: true,
              })
            );
            // refresh panel
            this.$emit("refresh");
          },
        },
      });
    },
    deleteJob() {
      f7.dialog
        .create({
          title: "Are you sure you want to delete this job?",
          verticalButtons: true,
          buttons: [
            {
              text: "Cancel",
              color: "gray",
            },
            {
              text: "Delete",
              color: "red",
              onClick: () => {
                deleteJob(this.pageState.job)
                  .then(() => {
                    this.f7router!.navigate("/jobs", {
                      reloadCurrent: true,
                    });
                    f7.toast.show({ text: "Job deleted.", closeTimeout: 1000 });

                    // refresh panel
                    this.$emit("refresh");
                  })
                  .catch(() => {
                    f7.toast.show({
                      text: "Failed to delete job, please try again later.",
                      closeTimeout: 1500,
                    });
                  });
              },
            },
          ],
        })
        .open();
    },
    printBOM() {
      this.printD.print(
        document.getElementById("bom-" + this.pageState.job.ID)!,
        [printStyle]
      );
    },
    printEngineeringForm() {
      this.printD.print(
        document.getElementById("EF-" + this.pageState.job.ID)!,
        [printStyle]
      );
    },
    printProgramDetails() {
      this.printD.print(
        document.getElementById("PD-" + this.pageState.job.ID)!,
        [printStyle]
      );
    },
    importProgram(p: ImportedProgram) {
      this.pageState.job.program.portMap = p.portMap;
      this.pageState.job.program.zones = p.zones;
      this.pageState.job.program.sets = p.sets;
      this.pageState.job.program.singlePump = p.singlePump;
      this.pageState.job.program.lastModifiedDate =
        firebase.firestore.Timestamp.now();
    },
    /** parse the BOM and add it to the jobs items */
    importBOM(e: any) {
      const file = e.target.files[0];
      if (!file) {
        f7.toast.show({
          text: "File not found.",
          closeTimeout: 1000,
        });
        return;
      }
      csvParser.parse(file, {
        complete: async (result) => {
          let data = result.data as Array<Array<string>>;
          this.pageState.job.bom = [];

          // get a list of the skus
          const _skus: string[] = [];
          for (let i = 1; i < data.length; i++) {
            const _itemData = data[i];
            const _count = Number.parseInt(_itemData[0]);
            if (!isNaN(_count)) {
              _skus.push(_itemData[1]);
            }
          }

          // fetch the items via the skus
          this.pageState.job.bom = await getItems(_skus);

          // after we've got the item data then add the count and notes
          for (let i = 1; i < data.length; i++) {
            const _itemData = data[i];
            const _count = Number.parseInt(_itemData[0]);
            const _sku = _itemData[1];
            const _notes = _itemData[2];
            const _item = this.pageState.job.bom.find((e) => e.sku == _sku)!;

            if (_item != undefined) {
              _item.updateCount(_count);
              if (_item.notes === "") {
                _item.notes = _notes;
              } else {
                _item.notes = _item.notes.concat(",", _notes);
              }
            }
          }
        },
      });
    },
    /**
     * masks time duration of seconds
     * e.g. if the duration is set to 30 min,
     * the input box will be masked to "00:30:00"
     * @param {number} duration - the duration in seconds
     * @returns {string} - the masked string
     */
    maskDuration(duration: number): string {
      if (!duration) {
        return "";
      }
      return new Date(duration * 1000).toISOString().substr(11, 8);
    },

    /**
     * parses the duration string to seconds
     * .e.g. "30:00" to 30*60 + 00
     * @param {any} event - the event object where the duration is stored
     * @param {number} zoneIndex - the index of the zone to store the duration
     */
    parseDuration(event: any, zoneIndex: number) {
      let v: string = event.target.value.replace(":", "");
      const minutes = parseInt(v.substr(0, v.length - 2));
      const seconds = parseInt(v.substr(v.length - 2));
      this.pageState.job.program.zones[zoneIndex].duration =
        minutes * 60 + seconds;
    },
    getPorts(zone: number) {
      let indexes: number[] = [];
      this.pageState.job.program.portMap.forEach((z, i) =>
        z + 1 == zone ? indexes.push(i + 1) : null
      );
      return indexes.join(",");
    },
  },
});
