
import {
  appState,
  auth,
  db,
  deleteBuilderFunction,
  getBuilderFunction,
  updatePasswordFunction,
} from "@/main";
import { f7 } from "framework7-vue";
import { send, title } from "process";
import { defineComponent, reactive, Ref, ref } from "vue";
import {
  Builder,
  checkBuilderID,
  createBuilder,
  Job,
  updateSearch,
} from "../../../ts/api";

export default defineComponent({
  props: {
    builderID: String,
    f7route: Object,
    f7router: Object,
  },
  setup(props) {
    let builder: Builder = reactive(new Builder());
    appState.activeBuilder = props.builderID ?? "new";

    // The jobs that belong to this builder
    let jobs: Ref<Job[]> = ref([]);
    let fetching = ref(true);

    async function getBuilder() {
      const builderData = await getBuilderFunction({
        builderID: props.builderID,
      }).then((res) => {
        console.log("got builder data:");
        console.log(res);
        return res.data;
      });

      console.log("builderData:");
      console.log(builderData);
      if (builderData != null) {
        builder.fromObject(builderData);

        // Get jobs
        jobs.value = [];
        db.collection("jobs")
          .where("builderID", "==", props.builderID)
          .get()
          .then((snap) => {
            snap.forEach(async (doc) =>
              jobs.value.push(await Job.fromDoc(doc))
            );
            fetching.value = false;
          });
      } else {
        f7.dialog.alert(
          "Builder data not found, please contact support. If you recently deleted this builder, please wait a few minutes for the data to be fully purged from the servers.",
          () => props.f7router?.navigate("/builders")
        );
      }
    }

    // If this is the new builder page
    const newBuilder: Ref<boolean> = ref(props.builderID === "new");

    // get the builder if we aren't making a new one
    if (!newBuilder.value) {
      getBuilder();
    }
    return {
      builder: builder,
      newCustomerNumber: ref(""),
      jobs: jobs,
      fetching: fetching,
      getBuilder: getBuilder,
      newBuilder: newBuilder,
    };
  },
  watch: {},
  methods: {
    copyID() {
      let _id = this.builderID;
      if (_id != undefined) navigator.clipboard.writeText(_id);
    },
    async save() {
      let _form: HTMLFormElement | null = this.$refs
        .builderForm as HTMLFormElement;

      // Check form validity
      if (_form?.checkValidity()) {
        // Check builder code
        if (this.newBuilder) {
          // Create new builder
          if (
            this.builder.builderID.length > 0 &&
            /^[A-Za-z0-9]+$/gm.test(this.builder.builderID)
          ) {
            if (!(await checkBuilderID(this.builder.builderID))) {
              const sendActivationEmail = await new Promise<boolean>(
                (resolve) =>
                  f7.dialog
                    .create({
                      title: "Before Creating This Builder...",
                      text: `Would you like to activate the partner login for ${this.builder.email}? This will provide them with updates on job progress and send them an account activation email. You can always send a password reset email later.`,
                      verticalButtons: true,
                      buttons: [
                        {
                          text: "SAVE & ACTIVATE PARTNER LOGIN",
                          onClick: () => resolve(true),
                        },
                        {
                          text: "SAVE ONLY",
                          onClick: () => resolve(false),
                        },
                      ],
                    })
                    .open()
              );
              let preloader = f7.dialog.preloader("Creating builder...");

              if (!sendActivationEmail) {
                this.builder.receiveEmails = false;
              }
              try {
                let newBuilderUID = await createBuilder(
                  this.builder,
                  this.newCustomerNumber,
                  sendActivationEmail
                );
                preloader.close();
                // Navigate to edit page for new builder
                this.f7router!.navigate("/builders/" + newBuilderUID, {
                  reloadCurrent: true,
                });
              } catch (error: any) {
                preloader.close();
                console.error(error);
                f7.dialog.alert(error.message ?? "Error creating builder, please try again later.");
              }
            } else {
              f7.dialog.alert("Builder code already taken.");
              console.log("invalid builder code");
            }
          } else {
            f7.dialog.alert("Builder code is invalid.");
            console.log("ERROR creating builder, code is invalid");
          }
        } else {
          let preloader = f7.dialog.preloader("Saving builder...");
          // Save to database
          await db
            .collection("builders")
            .doc(this.builderID)
            .set(this.builder.toFBObject());
          preloader.close();
        }
        setTimeout(() => updateSearch(), 5000);
      } else {
        f7.toast.show({
          text: "Invalid inputs. Please correct and save again.",
          closeButton: true,
          closeTimeout: 5000,
        });
      }
    },
    deleteBuilder() {
      f7.dialog.confirm(
        "This action can not be undone.",
        "Are you sure you want to delete this builder?",
        () => {
          let loading = f7.dialog.preloader("Deleting builder...");
          deleteBuilderFunction({ uid: this.builder.builderID })
            .then(() => {
              this.f7router!.navigate("/builders", {
                reloadCurrent: true,
              });
              f7.toast.show({ text: "Builder deleted.", closeTimeout: 1000 });
              setTimeout(() => updateSearch(), 5000);
            })
            .catch(() => {
              f7.toast.show({
                text: "Failed to delete builder, please try again later.",
                closeTimeout: 1500,
              });
            })
            .finally(() => {
              loading.close();
            });
        }
      );
    },
    resetPassword() {
      if (!this.builder.email) {
        f7.dialog.alert("No email found for this builder.");
        return;
      }
      const preloader = f7.dialog.preloader();
      auth
        .sendPasswordResetEmail(this.builder.email)
        .then(() => {
          preloader.close();
          f7.dialog.alert("Password reset email sent!");
        })
        .catch((e) => {
          preloader.close();
          console.error("Password reset email failed to send.", e);
          f7.dialog.alert("Error sending email, please contact support.");
        })
        .finally(() => preloader.destroy());
    },
    openChangePasswordDialog() {
      f7.dialog.prompt(
        'Enter new password',
        'Change Password',
        (newPassword) => {
          this.changePassword(newPassword);
        }
      );
    },
    async changePassword(newPassword: string) {

      if (!this.builder.email) {
        f7.dialog.alert("No email found for this builder.");
        return;
      }

      if (!this.$props.builderID) {
        f7.dialog.alert("No builder ID found.");
        return;
      }

      if (!newPassword) {
        f7.dialog.alert("No password entered.", () => this.openChangePasswordDialog());
        return;
      }

      if (newPassword.length < 6) {
        f7.dialog.alert("Password must be at least 6 characters long.", () => this.openChangePasswordDialog());
        return;
      }


      const preloader = f7.dialog.preloader();
      try {
        await updatePasswordFunction({ userId: this.$props.builderID, newPassword: newPassword });
        preloader.close();
        f7.dialog.alert("Password changed successfully!");
      } catch (e) {
        preloader.close();
        console.error("Password change failed.", e);
        await f7.dialog.alert("Error changing password, please contact support.");
      }
    },
  },
  computed: {
    appState: () => appState,
    permissionsSummary() {
      const permissions = ["Warehouse"];
      if (this.builder.roles.creditTerms) permissions.push("Credit Terms");
      if (this.builder.roles.canViewLoyaltyProgram) permissions.push("Loyalty Program");
      if (this.builder.roles.canSubmitJobs) permissions.push("Jobs");
      return permissions.join(", ");
    },
  },
});
