import algoliasearch from "algoliasearch";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { f7 } from "framework7-vue";
import {
  appState,
  createBuilderFunction,
  db,
  getBuilderFunction
} from "../main";
import { Job, JobState } from "../ts/api/job";
import { Address } from "./api/store";

export * from "../ts/api/job";
export * from "../ts/api/program";

const algoliaClient = algoliasearch(
  "QXIL9OSFZZ",
  "ad8ac70579e9a5d1f9c9428ef5dbce86"
);
export interface User {
  name: string;
  email: string;
  phone: string;
  notes: string;
}
// export interface Builder extends User {
//   builderID: string;
//   customerID: string;
//   savedAddresses: { [key: string]: Address };
//   // Note: added the full fishbowl customer for now if needed we can remove any data we dont use
//   customer: Customer;
// }

export class Builder {
  name?: string;
  email?: string;
  salesEmail?: string;
  phone?: string;
  notes?: string;
  receiveEmails: boolean;
  purchaseType: "direct" | "distribution";
  roles: {
    /** 
     * Determines whether or not the builder can split orders into 
     * different packages with different ship dates and addresses.
     */
    canSplitOrders: boolean;

    /** If true, the builder can checkout without paying immediately */
    creditTerms: boolean;

    /** Determines if the builder can view the loyalty page */
    canViewLoyaltyProgram: boolean;

    /** Determines if the builder can submit jobs */
    canSubmitJobs: boolean;
  }
  parentID?: string;
  builderID: string;
  customerID?: string;
  savedAddresses: { [key: string]: Address };
  customer: Customer; // Note: added the full fishbowl customer for now if needed we can remove any data we dont use

  constructor() {
    this.roles = {
      canSplitOrders: false,
      creditTerms: false,
      canViewLoyaltyProgram: false,
      canSubmitJobs: true,
    };
    this.builderID = "";
    this.savedAddresses = {};
    this.customer = {};
    this.receiveEmails = true;
    this.purchaseType = "direct";
  }

  /** Creates a Builder from an object
   * * For an example of this object see the FB function server
   */
  fromObject(_obj: any) {
    // set the data with added null checks. this section could use some cleanup
    this.name = _obj.name;
    this.email = _obj.email;
    this.salesEmail = _obj.salesEmail;
    this.phone = _obj.phone;
    this.notes = _obj.notes ?? "";
    this.roles.creditTerms = _obj.creditTerms ?? _obj.roles?.creditTerms ?? false;
    this.roles.canViewLoyaltyProgram = _obj.roles?.canViewLoyaltyProgram ?? false;
    this.roles.canSubmitJobs = _obj.roles?.canSubmitJobs ?? true;
    this.builderID = _obj.builderID!;
    this.customerID = _obj.customerID ?? "";
    this.savedAddresses = _obj.savedAddresses ?? [];
    this.customer = _obj.customer ?? {};
    this.receiveEmails = _obj.receiveEmails ?? true;
    this.roles.canSplitOrders = _obj.roles?.canSplitOrders ?? false;
    this.parentID = _obj.parentID;
    this.purchaseType = _obj.purchaseType ?? "direct";
  }

  /** Returns a firebase representation of this builder */
  toFBObject() {
   return {
      email: this.email ?? "",
      salesEmail: this.salesEmail ?? "",
      name: this.name ?? "",
      notes: this.notes ?? "",
      roles: {
        canSplitOrders: this.roles.canSplitOrders,
        creditTerms: this.roles.creditTerms,
        canViewLoyaltyProgram: this.roles.canViewLoyaltyProgram,
        canSubmitJobs: this.roles.canSubmitJobs,
      },
      phone: this.phone ?? "",
      savedAddresses: this.savedAddresses ?? [],
      customerID: this.customerID ?? "",
      receiveEmails: this.receiveEmails,
      parentID: this.parentID ?? null,
      purchaseType: this.purchaseType,
    };
  }
}

/** Fishbowl customer interface */
export interface Customer {
  id?: 6;
  accountid?: 176;
  accountinghash?: "";
  accountingid?: "";
  activeflag?: true;
  creditlimit?: 0;
  currencyrate?: 0;
  datecreated?: "2022-03-21T12:19:03.885-0600";
  datelastmodified?: "2022-03-24T21:11:48.373-0600";
  defaultpaymenttermsid?: 1;
  defaultshiptermsid?: 10;
  jobdepth?: 1;
  lastchangeduser?: "admin";
  name?: string;
  note?: "";
  number?: "1235";
  statusid?: 10;
  taxexempt?: false;
  taxexemptnumber?: "";
  taxrateid?: 1;
  tobeemailed?: true;
  tobeprinted?: true;
  url?: "";
  customfields?: "{}";
}

export function updateSearch() {
  // Update the search
  const _searchbar = f7.searchbar.get("#panel-search");
  const _searchValue = _searchbar.query;
  _searchbar.clear();
  _searchbar.search("test");
  _searchbar.search(_searchValue);
}

export interface result {
  id: string;
  title: string;
  subtitle: string;
  link: string;
}

export interface JobResult extends result {
  state: JobState;
}

// Searches algolia for the given query
export async function algoliaBuilderSearch({
  query,
}: {
  query: string;
}): Promise<result[]> {
  // Algolia searching
  // TODO: update algolia search app id/api key. See Peter for help

  const index = algoliaClient.initIndex("builderIndex");

  const _results: result[] = [];
  // let pageName = this.pageName;
  // console.log(this.pageName);

  const response = await index.search(query);
  response.hits.forEach((resp) => {
    _results.push({
      id: resp.objectID,
      subtitle: resp.objectID,
      // Cast resp to any to get around ts compilation error. The field is dynamic
      title: (resp as any).name,
      link: `/builders/${resp.objectID}`,
    });
  });
  return _results;
}

/** Searches the algolia job index by [query] then filters the state locally */
export async function algoliaJobSearch({
  query,
  states,
}: {
  query?: string;
  states?: JobState[];
}) {
  const index = algoliaClient.initIndex("jobIndex");

  const _results: JobResult[] = [];
  // let pageName = this.pageName;
  // console.log(this.pageName);

  const response = await index.search(query ?? "");
  response.hits.forEach((resp) => {
    // if there is a states filter then return on any jobs without specified states
    if (states != null) {
      if (!states.includes((resp as any).state)) {
        return;
      }
    }

    _results.push({
      id: resp.objectID,
      subtitle: resp.objectID,
      link: `/jobs/${resp.objectID}`,
      title: (resp as any).name,
      state: (resp as any).state,
    });
  });

  return _results;
}

// BUILDER SECTION
/** Creates a new builder with the given information */
export async function createBuilder(
  builderData: Builder,
  customerNumber: string,
  sendActivationEmail: boolean,
) {
  console.log("Creating new builder with builderID: " + builderData.builderID);
  // Call the cloud function to create the user
  const _result = await createBuilderFunction({
    builderData: builderData,
    customerNumber: customerNumber,
    sendActivationEmail: sendActivationEmail,
  });
  return _result.data;
}

/** Checks to see if the given builder code is taken true if taken*/
export async function checkBuilderID(builderID: string) {
  const _doc = await db.collection("builders").doc(builderID).get();
  return _doc?.exists ?? false;
}

/**
 * Builder only function
 *
 * Saves local address to builder doc
 * @returns void
 */
export async function saveAddresses(a: { [key: string]: Address }) {
  if (appState.admin) {
    throw "Must be a builder to cache addresses";
  }
  if (!a) {
    throw "No addresses found.";
  }
  if (appState.builderData) {
    a = { ...appState.builderData.savedAddresses, ...a };
    appState.builderData.savedAddresses = a;
  }
  return db.doc(`builders/${appState.userID}`).update({ savedAddresses: a });
}

/** Gets a builder from a builder ID */
export async function getBuilder(builderID: string) {
  const builder = new Builder();
  let jobs: Job[] = [];

  const builderData = await getBuilderFunction({
    builderID: builderID,
  }).then((res) => {
    return res.data;
  });

  if (builderData != null) {
    builder.fromObject(builderData);
    // get the builders jobs
    jobs = [];
    db.collection("jobs")
      .where("builderID", "==", builderID)
      .get()
      .then((snap) => {
        snap.forEach(async (doc) => jobs.push(await Job.fromDoc(doc)));
      });
  } else {
    throw "Builder data not found, please contact support. If you recently deleted this builder, please wait a few minutes for the data to be fully purged from the servers.";
  }

  return builder;
}
