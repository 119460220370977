
import { defineComponent, onMounted, onUnmounted } from 'vue';
import { f7 } from 'framework7-vue';
import { rewardsStore, Transaction } from '@/store/rewardsStore';
import { appState } from '@/main';

export default defineComponent({
  name: 'TransactionsList',
  // Removed transactions prop – state is now in the store.
  data() {
    return {
      currentTransaction: {
        price: 0,
        points: 0,
        description: '',
        id: '',
      } as Transaction,
      isSavingTransaction: false,
      isLoadingMoreTransactions: false,
    };
  },
  methods: {
    editTransaction(transaction: Transaction) {
      this.currentTransaction = { ...transaction };
      f7.popup.open('#add-transaction-popup');
    },
    newTransaction() {
      this.currentTransaction = { price: 0, points: 0, description: '', id: '' };
      f7.popup.open('#add-transaction-popup');
    },
    async saveTransaction() {
      if (this.currentTransaction.price && this.currentTransaction.points && this.currentTransaction.description) {
        this.isSavingTransaction = true;
        const res = await rewardsStore.updateTransaction(this.currentTransaction);
        this.isSavingTransaction = false;
        if (res.error) {
          f7.dialog.alert(res.error);
        } else {
          f7.popup.close('#add-transaction-popup');
        }
      } else {
        f7.dialog.alert('Please fill out all fields before saving the transaction.');
      }
    },
    async deleteTransaction(id: string) {
      f7.dialog.confirm(
        'Are you sure you want to delete this transaction?',
        'Delete Transaction',
        async () => {
          const preloader = f7.dialog.preloader('Deleting transaction...');
          const res = await rewardsStore.deleteTransaction(id);
          preloader.close();
          if (res.error) {
            f7.dialog.alert(res.error);
          }
        }
      );
    },
    async loadMoreTransactions() {
      if (this.loadedAllTransactions || this.isLoadingMoreTransactions) return;

      this.isLoadingMoreTransactions = true;
      const { error, allLoaded } = await rewardsStore.loadMoreTransactions();

      if (error) {
        f7.toast.show({
          text: error,
        });
      }

      if (allLoaded) {
        return;
      }

      this.isLoadingMoreTransactions = false;
    },
    formatDate(date?: Date): string {
      if (!date) return '';
      return new Date(date).toLocaleDateString();
    },
    formatPrice(price: number): string {
      return `$${price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    formatPoints(points: number): string {
      return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getText(transaction: Transaction): string {
      if (rewardsStore.salespersons.length === 0) return "";

      if (transaction.builderID === rewardsStore.currentUser?.builderID && !appState.admin) return "by you";

      const name = rewardsStore.salespersons.find(s => s.id === transaction.builderID)?.name;
      return `by ${name ?? transaction.builderID ?? "Unknown"}`;
    }
  },
  computed: {
    rewardsStore: () => rewardsStore,
    loadedAllTransactions: () => rewardsStore.transactionsPageToken === null,
    isAdmin: () => appState.admin,
  }
});
